import React from "react";
import {
  sectionForCategory,
} from "components/common/FooterAccordion";
import T from "types/index";
import { pathologiesGrandMotherSlug } from "templates/Pathologies/utils/slugs";
import { useAirtableCategories } from 'hooks/useAirtableCategories';
import SurFooter from "templates/Pathologies/common/SurFooter";

type Props = {
  breadcrumbLinks: T.Standard.BreadcrumbLink[];
  className?: string;
  categoryRecord: T.Charles.CategoryRecord;
};

const MotherSurFooter = ({
  breadcrumbLinks,
  className,
  categoryRecord,
}: Props) => {
  const categoriesRecords: T.Charles.CategoryRecord[] = useAirtableCategories(true);
  const otherCategories = categoriesRecords.filter(
    (categoryNode: T.Charles.CategoryRecord) =>
      categoryRecord.id !== categoryNode.id && !!categoryNode.data.pathologies
  );
  const accordionLinks = otherCategories.map(
    (currentCategory: T.Charles.CategoryRecord) =>
      sectionForCategory(currentCategory, [])
  );

  return (
    <SurFooter
      breadcrumbLinks={breadcrumbLinks}
      footerContent={{
        title: "Nos autres spécialités",
        titleLink: pathologiesGrandMotherSlug(),
        sections: accordionLinks,
      }}
      className={className}
    />
  );
};

export default MotherSurFooter;
