import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import { pathologyUrl } from "utils/pathologyUrlHelper";
import T from "types/index";
import { AirtableImages } from "components/common/utils/AirtableImage";

import './styles.sass'
import { shouldIndexPathology } from "hooks/useAirtableCategories";

type Props = {
  category: T.Charles.Category
  pathologies_title: string
  pathologies_subtitle: string
};

const PathologiesList = (props: Props) => {
  const indexedPathologies = props.category.pathologies.filter(shouldIndexPathology)
  return (
    <div className="container mb-5 pathologies-mother-component">
      <div className="pathologies-mother-title">
        <h2>{props.pathologies_title}</h2>
        <div>{props.pathologies_subtitle}</div>
      </div>
      <Container>
        <div className="pathologies-mother-wrapper">
          {indexedPathologies.map((pathology: T.Charles.PathologyRecord) => (
            <Link to={pathologyUrl(props.category.slug, pathology.data.slug)}>
              <div className="pathologies-mother-image-wrapper">
                <AirtableImages
                  images={pathology.data.thumbnail}
                  className="pathologies-mother-image"
                />
                <div className="pathologies-mother-image-title">
                  <h3>{pathology.data.breadcrumb_label}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  )
};

export default PathologiesList;
